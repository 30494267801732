h1 {
  @include fluid-type(30, 44, 480, 1200);
}

h2 {
  @apply font-quicksand leading-tight font-bold;
  &:not(.h3) {
    @include fluid-type(28, 40, 480, 1200);
  }
  &.has-ornament {
   @apply relative mb-20;
   &:before {
     content: "";
     @apply bg-green w-28 h-3 absolute left-1/2 transform -translate-x-1/2 -bottom-10 pointer-events-none
   ;} 
  }
}

h3,
.h3 {
  @apply font-quicksand text-3xl font-normal leading-none;
  @include fluid-type(21, 30, 480, 1200);
}

h4 {
  @apply font-quicksand font-bold text-lg;
  @include fluid-type(18, 25, 480, 1200);
}

h6,
.h6 {
  @apply text-xs tracking-widest font-bold block;
}

h1,
h2 {
  @apply font-quicksand font-bold block leading-none;
}