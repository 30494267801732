@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&family=Roboto&display=swap');

// Styles
@import 'app/assets/scss/mixins';
@import 'app/assets/scss/reset';

// Components
@import 'app/assets/scss/components/body';

// Partials
@import 'app/assets/scss/partials/titles';
@import 'app/assets/scss/partials/buttons';
